<template>
  <div class="solution">
    <!-- banner -->
    <el-row :gutter="20">
      <el-col :span="24">
        <Banner :options="banner_options">
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16"
              ><div class="slot_content container">
                <p class="title">
                  <span>智能</span>
                  社区综合解决方案
                </p>
                <p class="border_p">
                  <span></span>
                </p>
                <p>基于多种场景,提供优质的智慧化解决方案</p>
                <div>
                  <button class="key_button" @click="$router.push('/join')">
                    立即咨询
                  </button>
                </div>
              </div></el-col
            >
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </Banner>
      </el-col>
    </el-row>
    <!-- 三大块解决方案 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="content">
          <!-- menu -->
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container menu_box" flex between align>
                <div
                  class="item"
                  v-for="(item, index) in menu_list"
                  :key="index"
                  flex
                  center
                  align
                >
                  <div class="item_content" @click="scrollTo(item.$id)">
                    <img :src="item.bg_img" alt="" width="100%" height="100%" />
                    <div flex column center align>
                      <p>{{ item.title }}</p>
                      <p class="border_p"></p>
                      <ul>
                        <li v-for="(el, ind) in item.content_list" :key="ind">
                          {{ el }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <!-- 智能安防 -->
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container solution_con">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="title" flex column between id="security">
                      <p>智能安防</p>
                      <p></p>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="introduction_left">
                      <p class="con_tips">解决方案简介</p>
                      <p class="con_intro">
                        邻云管家智慧安防解决方案，依托于物联网、云计算、大数据、人工智能等新一代信息技术，实现智能门禁、智能监控、统一监控管理，提供高效、可靠、集约的智能安防门禁管理解决方案。
                      </p>
                    </div></el-col
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="function_left">
                      <p class="con_tips">解决方案功能</p>
                      <div class="left_con" flex column between align>
                        <div flex between align>
                          <div
                            v-for="(item, index) in security.list_01"
                            :key="index"
                            flex
                            column
                            center
                            align
                            class="item"
                            :style="item.style"
                          >
                            <i class="iconfont" :class="item.icon"></i>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div flex between align>
                          <div
                            v-for="(item, index) in security.list_02"
                            :key="index"
                            flex
                            column
                            center
                            align
                            :style="item.style"
                            class="item"
                          >
                            <i class="iconfont" :class="item.icon"></i>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                      </div>
                    </div></el-col
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="advantage_left">
                      <p class="con_tips">解决方案优势</p>
                      <div class="ad_con" flex align>
                        <div flex center align>
                          <i class="iconfont icon-dianzan"></i>
                          <p>
                            充分利用视频资源，通过视频智能分析、机器深度学习和大数据预测等技术，打造“智慧社区超脑平台”，让社区安防事件的发现从被动响应向主动防范转变，实现全自动、全方位、全天候监测。
                          </p>
                        </div>
                        <img
                          src="../../assets/img/安防.png"
                          width="100%"
                          height="100%"
                        />
                      </div></div
                  ></el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <!-- 智慧物业 -->
          <el-row :gutter="20" style="background: #fafafa">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16"
              ><div class="container solution_con">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="title" flex column between id="really">
                      <p>智慧物业</p>
                      <p></p>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="introduction_right">
                      <p class="con_tips">解决方案简介</p>
                      <p class="con_intro">
                        结合邻云管家智慧社区大数据管理平台，充分利用人工智能、物联网、云计算、大数据等新一代信息技术，实现对社区车辆、人员、事件的全面、精准管理，逐步建立完善社区周边大数据，为物业、社区、街道办、公安等部门提供相应的业务支撑，从而形成基于信息化、智能化管理与服务的全新社区治理体系。
                      </p>
                    </div></el-col
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="function_right">
                      <p class="con_tips">解决方案功能</p>
                      <div class="right_con" flex column between align>
                        <div flex between align>
                          <div
                            v-for="(item, index) in really.list_01"
                            :key="index"
                            flex
                            column
                            center
                            align
                            :style="item.style"
                            class="item"
                          >
                            <i class="iconfont" :class="item.icon"></i>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div flex between align>
                          <div
                            v-for="(item, index) in really.list_02"
                            :key="index"
                            flex
                            column
                            center
                            align
                            :style="item.style"
                            class="item"
                          >
                            <i class="iconfont" :class="item.icon"></i>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                      </div>
                    </div></el-col
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="advantage_right">
                      <p class="con_tips">解决方案优势</p>
                      <div class="ad_con" flex align>
                        <img
                          src="../../assets/img/物业1.png"
                          width="100%"
                          height="100%"
                        />
                        <div flex center align>
                          <i class="iconfont icon-dianzan"></i>
                          <p>
                            基于邻云物业数字平台，实现业主日常生活和物业管理的智能化、数字化、移动化、公开化。通过智能物业解决方案提升业主满意度，节能减排，降低管理成本提升管理效能。
                          </p>
                        </div>
                      </div>
                    </div></el-col
                  >
                </el-row>
              </div></el-col
            >
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <!-- 智能停车 -->
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16"
              ><div class="container solution_con">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="title" flex column between id="park">
                      <p>智能停车</p>
                      <p></p>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="introduction_left">
                      <p class="con_tips">解决方案简介</p>
                      <p class="con_intro">
                        通过物联网传感控制器、移动支付等技术，有效帮助地产、物业实现对市面不同品牌停车硬件的轻松管控，为运营方提供更全面、有效的数据支撑。
                      </p>
                    </div></el-col
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="function_left">
                      <p class="con_tips">解决方案功能</p>
                      <div class="left_con" flex column between align>
                        <div flex between align>
                          <div
                            v-for="(item, index) in park.list_01"
                            :key="index"
                            flex
                            column
                            center
                            align
                            :style="item.style"
                            class="item"
                          >
                            <i class="iconfont" :class="item.icon"></i>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div flex between align>
                          <div
                            v-for="(item, index) in park.list_02"
                            :key="index"
                            flex
                            column
                            center
                            align
                            :style="item.style"
                            class="item"
                          >
                            <i class="iconfont" :class="item.icon"></i>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                      </div>
                    </div></el-col
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24"
                    ><div class="advantage_left">
                      <p class="con_tips">解决方案优势</p>
                      <div class="ad_con" flex align>
                        <div flex center align>
                          <i class="iconfont icon-dianzan"></i>
                          <p>
                            智能停车大数据综合运营，实现人、车信息一体化，打破单一管理信息孤岛；通过移动应用等方式实现线下服务线上处理，如在线支付、预约车位等，满足多种支付体验。
                          </p>
                        </div>
                        <img
                          src="../../assets/img/停车1.png"
                          width="100%"
                          height="100%"
                        />
                      </div></div
                  ></el-col>
                </el-row></div
            ></el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 联系方式 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <Contact></Contact>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Banner from "@/components/banner.vue";
import Contact from "@/components/link.vue";
export default {
  components: {
    Banner,
    Contact,
  },
  data() {
    return {
      banner_options: {
        bg_img: require("@/assets/img/解决方案.png"),
      },
      // test_img: require("@/assets/img/bg_test.jpg"),
      menu_list: [
        {
          bg_img: require("@/assets/img/组 1.png"),
          title: "智能安防",
          content_list: ["解决方案简介", "解决方案功能", "解决方案优势"],
          $id: "security",
        },
        {
          bg_img: require("@/assets/img/物业.png"),
          title: "智慧物业",
          content_list: ["解决方案简介", "解决方案功能", "解决方案优势"],
          $id: "really",
        },
        {
          bg_img: require("@/assets/img/停车.png"),
          title: "智能停车",
          content_list: ["解决方案简介", "解决方案功能", "解决方案优势"],
          $id: "park",
        },
      ],
      security: {
        list_01: [
          {
            icon: "icon-renlianshibie1",
            name: "人脸识别",
            style: {
              top: 0,
            },
          },
          {
            icon: "icon-zhinengmenjin",
            name: "智能门禁",
            style: {
              top: "-50px",
            },
          },
          {
            icon: "icon-dashujufenxi",
            name: "大数据分析",
            style: {
              top: "-100px",
            },
          },
          {
            icon: "icon-jiankong",
            name: "智能监控",
            style: {
              top: "-150px",
            },
          },
        ],
        list_02: [
          {
            icon: "icon-fanghukaiqi",
            name: "周界防护",
            style: {
              top: 0,
            },
          },
          {
            icon: "icon-xungengdianguanli",
            name: "巡更巡逻",
            style: {
              top: "-50px",
            },
          },
          {
            icon: "icon-keeper-tonghangjilux",
            name: "通行记录",
            style: {
              top: "-100px",
            },
          },
          {
            icon: "icon-heimingdan11",
            name: "黑名单管理",
            style: {
              top: "-150px",
            },
          },
        ],
      },
      really: {
        list_01: [
          {
            icon: "icon-iconzhinengfangkerenlianqiandao",
            name: "智能访客",
            style: {
              top: "-150px",
            },
          },
          {
            icon: "icon-baoxiujilu",
            name: "报事报修",
            style: {
              top: "-100px",
            },
          },
          {
            icon: "icon-shujufenxi1",
            name: "社区数据分析",
            style: {
              top: "-50px",
            },
          },
          {
            icon: "icon-wuyejiaofei",
            name: "物业缴费",
            style: {
              top: 0,
            },
          },
        ],
        list_02: [
          {
            icon: "icon-zichanguanli4",
            name: "资产管理",
            style: {
              top: "-150px",
            },
          },
          {
            icon: "icon-ziyuan1",
            name: "公告通知",
            style: {
              top: "-100px",
            },
          },
          {
            icon: "icon-fangchan",
            name: "房产管理",
            style: {
              top: "-50px",
            },
          },
          {
            icon: "icon-shebeiguanli2",
            name: "设备管理",
            style: {
              top: 0,
            },
          },
        ],
      },
      park: {
        list_01: [
          {
            icon: "icon-weibiaoti--",
            name: "车辆管理",
            style: {
              top: 0,
            },
          },
          {
            icon: "icon-chezhuguanli",
            name: "车主管理",
            style: {
              top: "-50px",
            },
          },
          {
            icon: "icon-chekuguanli",
            name: "车库管理",
            style: {
              top: "-100px",
            },
          },
          {
            icon: "icon-qujingfuyou-", 
            name: "缴费管理",
            style: {
              top: "-150px",
            },
          },
        ],
        list_02: [
          {
            icon: "icon-chepaishibie",
            name: "车辆无感通行",
            style: {
              top: 0,
            },
          },
          {
            icon: "icon-cheliangguanli",
            name: "访客车辆管理",
            style: {
              top: "-50px",
            },
          },
          {
            icon: "icon-park",
            name: "车位预约",
            style: {
              top: "-100px",
            },
          },
          {
            icon: "icon-shujufenxi2",
            name: "数据分析",
            style: {
              top: "-150px",
            },
          },
        ],
      },
    };
  },
  mounted() {
    if (this.$route.query.$id) {
      this.scrollTo(this.$route.query.$id);
    } else {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    scrollTo($id) {
      document.getElementById($id).scrollIntoView(true);
    },
  },
};
</script>
<style lang="less" scoped>
.solution {
  min-width: 1200px;
}
.slot_content {
  > p:last-of-type {
    font-size: 20px;
    padding-top: 20px;
  }
  .border_p {
    height: 4px;
    > span {
      background-color: #fff;
      display: inline-block;
      width: 580px;
      height: 4px;
    }
  }
  .title {
    font-size: 72px;
    > span {
      color: rgba(247, 137, 0, 1);
    }
  }
}
.content {
  .menu_box {
    margin-bottom: 150px;
    height: 386px;
    .item {
      padding: 20px 16px;
      background-color: #fafafa;
      cursor: pointer;
      .item_content {
        position: relative;
        width: 100%;
        height: 100%;
        > div {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(39, 49, 67, 0.4);
          z-index: 131;
          color: #fff;
          > p:nth-of-type(1) {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
          }
          > .border_p {
            width: 60%;
            height: 4px;
            background-color: #fff;
            margin: 18px 0 35px 0;
          }
          // > ul {
          //   font-size: 20px;
          //   font-family: Source Han Sans SC;
          //   font-weight: 400;
          //   line-height: 48px;
          // }
        }

        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 130;
        }
      }
      .item_content:hover .border_p {
        background-color: rgba(247, 137, 0, 1);
      }
    }
    .item:hover {
      box-shadow: 0px 5px 11px 1px rgba(23, 29, 38, 0.16);
    }
  }
  .solution_con:nth-of-type(2) {
    background: #fafafa;
  }
  .solution_con {
    padding-top: 50px;
    .con_tips {
      font-size: 28px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #333333;
      line-height: 43px;
      padding: 25px 0 25px 0;
      text-align: left;
    }
    .con_intro {
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      line-height: 48px;
      padding: 5px 0 100px 0;
      text-align: left;
    }
    .introduction_right {
      .con_tips,
      .con_intro {
        text-align: right;
      }
    }
    .function_left {
      .con_tips {
        padding: 10px 0 100px 0;
      }
    }
    .function_right {
      .con_tips {
        text-align: right;
        padding: 10px 0 100px 0;
      }
    }
    .advantage_right {
      .con_tips {
        text-align: right;
        padding: 100px 0 50px 0;
      }
      .ad_con {
        img {
          right: -140px;
        }
      }
    }
    .advantage_left {
      .con_tips {
        padding: 100px 0 50px 0;
      }
      .ad_con {
        img {
          left: -140px;
        }
      }
    }
    .left_con,
    .right_con {
      width: 1200px;
      > div {
        width: 100%;
        height: 290px;
        margin: 25px 0;
      }
      .item {
        width: 22%;
        height: 100%;
        position: relative;
        background: #ffffff;
        box-shadow: 0px 4px 30px 0px rgba(15, 15, 15, 0.1);
        border-radius: 4px;
        color: rgba(128, 128, 128, 1);
        .iconfont {
          font-size: 65px;
        }
        > p {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          padding-top: 50px;
        }
      }
      .item:hover {
        box-shadow: 0px 4px 30px 0px rgba(247, 137, 0, 0.1);
        color: rgba(247, 137, 0, 1);
        > p {
          color: rgba(247, 137, 0, 1);
        }
      }
    }
    .ad_con {
      padding-bottom: 150px;
      > div {
        background: #ffffff;
        box-shadow: 0px 4px 30px 0px rgba(15, 15, 15, 0.1);
        border-radius: 4px;
        z-index: 135;
        position: relative;
      }
      p {
        text-align: left;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        position: absolute;
        pointer-events: none;
      }
      .iconfont {
        font-size: 100px;
        color: #ebebeb;
      }
      .iconfont:hover {
        color: #fde7cc;
      }
      img {
        z-index: 130;
        position: relative;
        top: 0;
      }
    }
  }
}
@media screen and(max-width:1200px) {
  .slot_content {
    top: 150px;
    > p:last-of-type {
      font-size: 16px;
      padding-top: 15px;
    }
    .border_p {
      height: 4px;

      > span {
        width: 580px;
        height: 4px;
      }
    }
    .title {
      font-size: 50px;
    }
  }
  .title {
    font-size: 25px;
    line-height: 30px;
    height: 60px;
  }
  .content {
    .menu_box {
      margin-bottom: 80px;
      height: 280px;
      margin-top: 20px;
      width: 800px;
      .item {
        padding: 10px;
        .item_content {
          width: 235px;
          height: 235px;
          > div {
            > p:nth-of-type(1) {
              font-size: 18px;
            }
            > .border_p {
              margin: 8px 0 20px 0;
            }
            > ul {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
    .solution_con {
      padding-top: 50px;
      .introduction_right,
      .introduction_left,
      .function_left,
      .function_right,
      .advantage_left,
      .advantage_right {
        width: 800px;
      }
      .con_tips {
        font-size: 20px;
        line-height: 43px;
        padding: 25px 0 25px 0;
      }
      .con_intro {
        font-size: 16px;
        line-height: 30px;
        padding: 5px 0 70px 0;
      }
      .function_left {
        .con_tips {
          padding: 10px 0 100px 0;
        }
      }
      .function_right {
        .con_tips {
          padding: 10px 0 100px 0;
        }
      }
      .advantage_right {
        .con_tips {
          padding: 100px 0 50px 0;
        }
        .ad_con {
          img {
            right: -140px;
          }
        }
      }
      .advantage_left {
        .con_tips {
          padding: 100px 0 50px 0;
        }
        .ad_con {
          img {
            left: -140px;
          }
        }
      }
      .left_con,
      .right_con {
        width: 800px;
        > div {
          margin: 25px 0;
          height: 194px;
        }
        .item {
          .iconfont {
            font-size: 50px;
          }
          > p {
            font-size: 18px;
            padding-top: 30px;
          }
        }
      }
      .ad_con {
        padding-bottom: 150px;
        > div {
          width: 384px;
          height: 150px;
        }
        p {
          font-size: 14px;
          line-height: 30px;
          top: 8%;
          left: 6.5%;
          width: 90%;
        }
        .iconfont {
          font-size: 100px;
        }
        img {
          width: 437.5px;
          height: 250px;
        }
      }
    }
  }
}
@media screen and(min-width:1200.1px) and(max-width:1500px) {
  .slot_content {
    top: 350px;
    .border_p {
      height: 4px;
      padding: 35px 0;
      > span {
        width: 580px;
        height: 4px;
      }
    }
  }
  .content {
    .menu_box {
      margin-bottom: 120px;
      height: 320px;
      margin-top: 70px;
      width: 1000px;
      .item {
        padding: 15px;
        .item_content {
          width: 280px;
          height: 280px;
          > div {
            > p:nth-of-type(1) {
              font-size: 20px;
            }
            > .border_p {
              margin: 12px 0 28px 0;
            }
            > ul {
              font-size: 18px;
              line-height: 35px;
            }
          }
        }
      }
    }
    .solution_con {
      padding-top: 50px;
      .introduction_right,
      .introduction_left,
      .function_left,
      .function_right,
      .advantage_left,
      .advantage_right {
        width: 1000px;
      }
      .con_tips {
        font-size: 22px;
        line-height: 35px;
        padding: 25px 0 25px 0;
      }
      .con_intro {
        font-size: 16px;
        line-height: 35px;
        padding: 5px 0 70px 0;
      }
      .function_left {
        .con_tips {
          padding: 10px 0 80px 0;
        }
      }
      .function_right {
        .con_tips {
          padding: 10px 0 80px 0;
        }
      }
      .advantage_right {
        .con_tips {
          padding: 80px 0 40px 0;
        }
        .ad_con {
          img {
            right: -140px;
          }
        }
      }
      .advantage_left {
        .con_tips {
          padding: 80px 0 40px 0;
        }
        .ad_con {
          img {
            left: -140px;
          }
        }
      }
      .left_con,
      .right_con {
        width: 1000px;
        > div {
          margin: 25px 0;
          height: 242px;
        }
        .item {
          .iconfont {
            font-size: 55px;
          }
          > p {
            font-size: 20px;
            padding-top: 40px;
          }
        }
      }
      .ad_con {
        padding-bottom: 150px;
        > div {
          width: 512px;
          height: 200px;
        }
        p {
          font-size: 16px;
          line-height: 36px;
          top: 12%;
          left: 9%;
          width: 87%;
        }
        .iconfont {
          font-size: 100px;
        }
        img {
          width: 699px;
          height: 300px;
        }
      }
    }
  }
}
@media screen and(min-width:1500.1px) and(max-width:1700px) {
  .slot_content {
    top: 350px;
    .border_p {
      height: 4px;
      padding: 35px 0;
      > span {
        width: 580px;
        height: 4px;
      }
    }
  }
  .content {
    .menu_box {
      margin-bottom: 150px;
      margin-top: 70px;
      height: 386px;
      width: 1200px;
      .item {
        padding: 20px;
        .item_content {
          width: 346px;
          height: 346px;
          > div {
            > p:nth-of-type(1) {
              font-size: 24px;
            }
            > .border_p {
              margin: 18px 0 35px 0;
            }
            > ul {
              font-size: 20px;
              line-height: 48px;
            }
          }
        }
      }
    }
    .solution_con {
      padding-top: 50px;
      .introduction_right,
      .introduction_left,
      .function_left,
      .function_right,
      .advantage_left,
      .advantage_right {
        width: 1200px;
      }
      .con_tips {
        font-size: 28px;
        line-height: 43px;
        padding: 25px 0 25px 0;
      }
      .con_intro {
        font-size: 16px;
        line-height: 48px;
        padding: 5px 0 100px 0;
      }
      .function_left {
        .con_tips {
          padding: 10px 0 100px 0;
        }
      }
      .function_right {
        .con_tips {
          padding: 10px 0 100px 0;
        }
      }
      .advantage_right {
        .con_tips {
          padding: 100px 0 50px 0;
        }
        .ad_con {
          img {
            right: -140px;
          }
        }
      }
      .advantage_left {
        .con_tips {
          padding: 100px 0 50px 0;
        }
        .ad_con {
          img {
            left: -140px;
          }
        }
      }
      .left_con,
      .right_con {
        width: 1200px;
        > div {
          margin: 25px 0;
        }
        .item {
          width: 264px;
          height: 290px;
          .iconfont {
            font-size: 65px;
          }
          > p {
            font-size: 24px;
            padding-top: 50px;
          }
        }
      }
      .ad_con {
        padding-bottom: 150px;
        > div {
          width: 640px;
          height: 250px;
        }
        p {
          font-size: 16px;
          line-height: 36px;
          top: 20%;
          left: 15%;
          width: 69%;
        }
        .iconfont {
          font-size: 100px;
        }
        img {
          width: 700px;
          height: 400px;
        }
      }
    }
  }
}
@media screen and(min-width:1700.1px) {
  .slot_content {
    top: 350px;
    .border_p {
      height: 4px;
      padding: 35px 0;
      > span {
        width: 580px;
        height: 4px;
      }
    }
  }
  .content {
    .menu_box {
      margin-bottom: 150px;
      margin-top: 70px;
      height: 386px;
      .item {
        padding: 20px;
        .item_content {
          width: 346px;
          height: 346px;
          > div {
            > p:nth-of-type(1) {
              font-size: 24px;
            }
            > .border_p {
              margin: 18px 0 35px 0;
            }
            > ul {
              font-size: 20px;
              line-height: 48px;
            }
          }
        }
      }
    }
    .solution_con {
      padding-top: 50px;
      .introduction_right,
      .introduction_left,
      .function_left,
      .function_right,
      .advantage_left,
      .advantage_right {
        width: 1200px;
      }
      .con_tips {
        font-size: 28px;
        line-height: 43px;
        padding: 25px 0 25px 0;
      }
      .con_intro {
        font-size: 16px;
        line-height: 48px;
        padding: 5px 0 100px 0;
      }
      .function_left {
        .con_tips {
          padding: 10px 0 100px 0;
        }
      }
      .function_right {
        .con_tips {
          padding: 10px 0 100px 0;
        }
      }
      .advantage_right {
        .con_tips {
          padding: 100px 0 50px 0;
        }
        .ad_con {
          img {
            right: -140px;
          }
        }
      }
      .advantage_left {
        .con_tips {
          padding: 100px 0 50px 0;
        }
        .ad_con {
          img {
            left: -140px;
          }
        }
      }
      .left_con,
      .right_con {
        width: 1200px;
        > div {
          margin: 25px 0;
        }
        .item {
          width: 264px;
          height: 290px;
          .iconfont {
            font-size: 65px;
          }
          > p {
            font-size: 24px;
            padding-top: 50px;
          }
        }
      }
      .ad_con {
        padding-bottom: 150px;
        > div {
          width: 640px;
          height: 250px;
        }
        p {
          font-size: 16px;
          line-height: 36px;
          top: 20%;
          left: 15%;
          width: 69%;
        }
        .iconfont {
          font-size: 100px;
        }
        img {
          width: 700px;
          height: 400px;
        }
      }
    }
  }
}
</style>